import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import * as $ from 'jquery';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';

import { SeguridadClienteImp } from '../../../Services/ImplementacionServicios/seguridad-cliente-imp';
import { LoginServiceImp } from '../../../Services/ImplementacionServicios/login-service-imp';
import { TokenImpService } from '../../../Services/ImplementacionServicios/token-imp';
@Component({
  selector: 'app-cambio-clave-inicial',
  templateUrl: './cambio-clave-inicial.component.html',
  styleUrls: ['./cambio-clave-inicial.component.css']
})
export class CambioClaveInicialComponent implements OnInit {
  public alfanumerica: string;
  public minimo: string;
  public maximo: string;
  constructor(
    private route: Router,
    private messageService: MessageService,
    private loginServiceImp: LoginServiceImp,
    private token: TokenImpService,
    private SpinnerService: NgxSpinnerService,
    private seguridadService: SeguridadClienteImp
  ) { }

  ngAfterViewInit() {
    this.getParametrosCambioClave();
  }


  ngOnInit(): void {

  }




  CambiarClaveWs(form) {
    this.SpinnerService.show("sp6");
    let usuario = this.token.getCodUser();
    let Actual = form.form.value.Actual;
    let Nueva = form.form.value.Nueva;
    let ConfirmarNueva = form.form.value.ConfirmarNueva;
    let conexionPr = this.token.obtenerUltimaConexion();
    if (Nueva == ConfirmarNueva) {
      if (conexionPr == "N/A") {
        this.SpinnerService.show();
        this.loginServiceImp.cambioClavePrimerIngreso(btoa(usuario.toString()), btoa(Actual), btoa(Nueva), btoa(ConfirmarNueva)).then(
          (data: any) => {
            this.SpinnerService.hide("sp6");
            if (data.bRta == false) {
              this.messageService.add({ key: 'msgToast', severity: 'warn', summary: '¡Alerta!', detail: data.sMsj });
            } else {
              this.messageService.add({ key: 'msgToast', severity: 'success', summary: '¡Éxito!', detail: data.sMsj });
              this.route.navigate(['PincWeb/Cliente/homeCliente']);
            }
          }
        );
      } else {
        this.SpinnerService.show();
        this.seguridadService.cambioClavePrimerIngreso(btoa(usuario.toString()), btoa(Actual), btoa(Nueva), btoa(ConfirmarNueva)).then(
          (data: any) => {
            this.SpinnerService.hide("sp6");
            if (data.bRta == false) {
              this.messageService.add({ key: 'msgToast', severity: 'warn', summary: '¡Alerta!', detail: data.sMsj });
            } else {
              this.messageService.add({ key: 'msgToast', severity: 'success', summary: '¡Éxito!', detail: data.sMsj });
              this.route.navigate(['PincWeb/Cliente/homeCliente']);
            }
          }
        )
      }
    } else {
      this.SpinnerService.hide("sp6");
      this.messageService.add({ key: 'msgToast', severity: 'warn', summary: '¡Alerta!', detail: 'La clave nueva no coincide con la de confirmación' });
    }

  }

  formatoClave(maximo, minimo, alfanumerico) {
    $("#claveNueva").on({
      "focus": function (event) {
        $(event.target).select();
      },
      "keyup": function (event) {
        $(event.target).val(function (index, value) {
          if (alfanumerico == "SI") {
            let digixClave = value.length;
            let minuscula = false;
            let mayuscula = false;
            let numerico = false;
            let arrayNumeros = "0123456789";
            if (digixClave <= maximo && digixClave >= minimo) {
              this.cumpleLongitud = true;
              $("#longitud").css({ 'display': 'none' });
            }
            else {
              $("#longitud").css({ 'display': 'block' });
            }
            for (let index = 0; index < digixClave; index++) {
              let caracter = value.charAt(index);
              if (caracter == caracter.toLowerCase() && arrayNumeros.indexOf(caracter, 0) == -1) {
                minuscula = true;
              }
              if (caracter == caracter.toUpperCase() && arrayNumeros.indexOf(caracter, 0) == -1) {
                mayuscula = true;
              }
              if (arrayNumeros.indexOf(value.charAt(index), 0) != -1) {
                numerico = true;
              }
            }

            if (!minuscula) {
              $("#minuscula").css({ 'display': 'block' });
            }
            else {
              $("#minuscula").css({ 'display': 'none' });
            }
            if (!mayuscula) {
              $("#mayus").css({ 'display': 'block' });
            }
            else {
              $("#mayus").css({ 'display': 'none' });
            }
            if (!numerico) {
              $("#numero").css({ 'display': 'block' });
            }
            else {
              $("#numero").css({ 'display': 'none' });
            }
          }
          return value;
        });
      }
    });
  }


  getParametrosCambioClave() {
    this.SpinnerService.show("sp6");
    this.seguridadService.obtenerParametros().then(
      (response: any) => {
        this.alfanumerica = response.alfanumerico;
        this.maximo = response.cantMaxDig;
        this.minimo = response.canMinDig;
        this.SpinnerService.hide("sp6");
        this.formatoClave(this.maximo, this.minimo, this.alfanumerica);
      }
    )
  }

}
