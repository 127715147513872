import { Injectable } from '@angular/core';
import { SeguridadClienteService } from '../ConsumoServicios/seguridad-cliente.service';
import { RespuestaGenerica } from 'src/app/Model/Response/RespuestaGenerica';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable({
   providedIn: 'root'
})
export class SeguridadClienteImp {



   constructor(
      private seguridadService: SeguridadClienteService
   ) { }

   cambioClavePrimerIngreso(xCodUsuario: string, xClaveAntigua: string, xClaveNueva: string, xClaveNuevaConf: string) {
      return new Promise(resolve => {
         this.seguridadService.cambioClavePrimerIngreso(xCodUsuario, xClaveAntigua, xClaveNueva, xClaveNuevaConf).subscribe(
            (response: any) => {
               resolve(response);
            }
         );
      });
   }

   obtenerParametros() {
      return new Promise(resolve => {
         this.seguridadService.parametrosClave().subscribe(
            (response: any) => {
               resolve(response);
            }
         )
      });
   }

   validarIdentidad(xiCodUser: any, sFechaExp: string) {
    return new Promise(resolve => {
       this.seguridadService.validarIdentidadAsociado(xiCodUser, sFechaExp).subscribe(
          (response: RespuestaGenerica) => {
             resolve(response);
          }
       );
    });
 }

   generarNuevaClave(xData: string) {
      return new Promise(resolve => {
        this.seguridadService.generarNuevaClave(xData).subscribe(
          (response: RespuestaGenerica) => {
            resolve(response);
          },
          (error: HttpErrorResponse) => {
           resolve(null);
          }
        );
      });
    }

    desbloquearUsuario(xData: string) {
      return new Promise(resolve => {
        this.seguridadService.desbloquearUsuario(xData).subscribe(
          (response: RespuestaGenerica) => {
            resolve(response);
          },
          (error: HttpErrorResponse) => {
           resolve(null);
          }
        );
      });
    }


}
