
<div class="row col-md-12 ml-5 mt-1 pt-1 responsiveContent">
  <div class="col-md-5 card-credito">
    <div class="p-4">
      <div class="card-body pt-3">
        <div class="tabla">
          <div>
            <table mat-table class="tablaAs" [dataSource]="dataSource" matSort>

              <ng-container matColumnDef="nombres">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Nombres </th>
                <td mat-cell *matCellDef="let element" class="asociado">{{element.CNombres}}</td>
              </ng-container>

              <ng-container matColumnDef="estado">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Estado </th>
                <td mat-cell *matCellDef="let element" class="asociado">{{element.CEstado}}</td>
              </ng-container>

              <ng-container matColumnDef="acciones">
                <th mat-header-cell *matHeaderCellDef class="th-acciones"> Acciones </th>
                <td mat-cell *matCellDef="let element" class="td-acciones">
                  <span title="Configurar" (click)="infoAsociado(element)"><i class="material-icons">settings</i></span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
<!--                  <span title="Modificar datos" (click)="editarAsociado(element)" class="material-icons">edit</span>-->
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumns "></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns; "></tr>
            </table>
            <mat-paginator [pageSize]="pageSize " [pageSizeOptions]="pageSizeOptions ">
            </mat-paginator>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-md-7 card-credito">
    <nav class="navbar navbar-expand-sm navbar-sucess my-navbar barra">
      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarNav">
        <strong>
          <ul class="navbar-nav">
            <!--            <mat-icon>location_off</mat-icon>-->
            <li class="nav-item active">
              <a class="nav-link" href="#"><p> <i class="fa fa-pencil"></i> Enviar</p></a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#"><p><i class="fa fa-pencil-square-o"></i> Editar</p></a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#"><p><i class="fa fa-bars "></i> Otras</p></a>
            </li>
          </ul>
        </strong>
      </div>
    </nav>
    <div class="p-4">
      <div class="card-body pt-3">
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
</div>
