import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { RespuestaGenerica } from '../../Model/Response/RespuestaGenerica';
import { Persona } from '../../Model/DTO/Persona';
import { RespuestaOlvidoClave } from '../../Model/Response/RespuestaOlvidoClave';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  public url = environment.URL_SERVICE + "/LoginPinc";
  public headers = new HttpHeaders(
    {
      "Content-Type": "application/json; charset=UTF-8",
      "Authorization": "Bearer" + sessionStorage.getItem("token")
    }
  );

  constructor(private http: HttpClient) { }

  loginCliente(xTipoPerfil: number,xTipoIdent: string, xIdent: string, xClave: string, xNavegador: string) {
    let path = this.url + "/Cliente/loginCliente";
    path += "?iTipoPerfil=" + xTipoPerfil;
    path += "&xiTipoIden=" + xTipoIdent;
    path += "&xcIdentificacion=" + xIdent;
    path += "&xcClave=" + xClave;
    path += "&xcNavegador=" + xNavegador;
    path += "&xiTipoCanal=1";
    return this.http.post<string>(path, { headers: this.headers });
  }

  olvidoClave(xTipoIdent: string, xIdent: string, xClave: string, xNavegador: string) {
    let path = this.url + "/Cliente/olvidoClave";
    path += "?xiTipoIden=" + xTipoIdent;
    path += "&xcIdentificacion=" + xIdent;
    path += "&xcClave=" + xClave;
    path += "&xcNavegador=" + xNavegador;
    path += "&xiTipoCanal=1";
    return this.http.post<string>(path, { headers: this.headers });

  }

  cambioClavePrimerIngreso(xCodUsuario: string, xClaveAntigua: string, xClaveNueva: string, xClaveNuevaConf: string) {
    let path = this.url + "/Cliente/cambioClavePrimerIngreso";
    path += "?xCodUsuario=" + xCodUsuario;
    path += "&xClaveAntigua=" + xClaveAntigua;
    path += "&xClaveNueva=" + xClaveNueva;
    path += "&xClaveNuevaConf=" + xClaveNuevaConf;
    return this.http.post<RespuestaGenerica>(path, { headers: this.headers });

  }

  loginUsuarioEmpleado(xiTipoPerfil: string, xTipoIdent: string, xIdent: string, xClave: string, xNavegador: string) {
    let path = this.url + "/Empleado/loginUsuarioEmpleado";
    path += "?iTipoPerfil=" + xiTipoPerfil;
    path += "&xiTipoIden=" + xTipoIdent;
    path += "&xcIdentificacion=" + xIdent;
    path += "&xcClave=" + xClave;
    path += "&xcNavegador=" + xNavegador;
    path += "&xiTipoCanal=1";
    return this.http.post<string>(path, { headers: this.headers });
  }

  validarPersona(xiTipoIden: string, xcIdentificacion: string) {
    let path = this.url + "/validarPersona";
    path += "?xiTipoIden=" + xiTipoIden;
    path += "&xcIdentificacion=" + xcIdentificacion;
    return this.http.post<Persona>(path, { headers: this.headers });
  }

  cerrarSession(xiUsuario: string) {
    let path = this.url + "/CerrarSession";
    path += "?iUsuario=" + xiUsuario;
    path += "&idTipoCanal=1";
    return this.http.post<RespuestaGenerica>(path, { headers: this.headers });

  }

  validarOlvidoClave(xData: string) {
    let path = this.url + "/validaOlvidoClave";
    path += "?xsData=" + xData;
    return this.http.post<RespuestaOlvidoClave>(path, { headers: this.headers });

  }

  generarNuevaClave(xData: string) {
    let path = this.url + "/generarNuevaClaveWeb";
    path += "?xsData=" + xData;
    return this.http.post<RespuestaGenerica>(path, { headers: this.headers });
  }


}
