<app-menu></app-menu>
<div class="row col-md-12 ml-5 mt-1 pt-1 responsiveContent">
  <div class="col-md-5 card-credito">
    <div class="p-4">
      <div class="card-body pt-3">
        <div class="container-fluid">
          <div class="row">
            <div class="col-12 collapse show d-md-flex bg-light pt-2 pl-0" id="sidebar">
              <ul class="nav flex-column flex-nowrap overflow-hidden">
                <li class="nav-item">
                  <a class="nav-link collapsed text-truncate" href="#submenu1" data-toggle="collapse" data-target="#submenu1"><span class="d-none d-sm-inline">Solicitud 64515</span></a>
                  <div class="collapse" id="submenu1" aria-expanded="false">
                    <ul class="flex-column pl-2 nav">
                      <li class="nav-item"><a class="nav-link py-0" href="#"><span>Documentos 1</span></a></li>
                      <li class="nav-item">
                        <a class="nav-link collapsed py-1" href="#submenu1sub1" data-toggle="collapse" data-target="#submenu1sub1"><span>Documentos 2</span></a>
                        <div class="collapse" id="submenu1sub1" aria-expanded="false">
                          <ul class="flex-column nav pl-4">
                            <li class="nav-item">
                              <a class="nav-link p-1" routerLink="/sesion/completar-firmar/documento">
                                <i class="fa fa-file"></i> Pagare inmaterializado </a>
                            </li>
                            <li class="nav-item">
                              <a class="nav-link p-1" routerLink="/sesion/completar-firmar/documento">
                                <i class="fa fa-file"></i> Carta de instrucciones </a>
                            </li>
                          </ul>
                        </div>
                      </li>
                    </ul>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="md-stepper-horizontal">
        <div class="md-step" id="stepDiligenciado">
            <i class="far fa-file fa-2x icono"></i>
          <div class="md-step-optional">
            <!-- <span class="stepFecha">30/02/2020</span> -->
          </div>
          <!-- <div class="md-step-bar-left"></div> -->
          <div class="md-step-bar-right" id="lineaDilRad"></div>
        </div>
        <div class="md-step" id="stepRadicado">
          <i class="far fa-edit fa-2x icono"></i>
          <div class="md-step-optional">
            <!-- <span class="stepFecha">30/02/2020</span> -->
          </div>
          <div class="md-step-bar-left" id="lineaRadDil"></div>
          <div class="md-step-bar-right" id="lineaRadEstu"></div>
        </div>
        <div class="md-step" id="stepEstudio">
          <i class="far fa-edit fa-2x icono"></i>
          <div class="md-step-optional">
            <!-- <span class="stepFecha">30/02/2020</span> -->
          </div>
          <div class="md-step-bar-left" id="lineaEstRad"></div>
          <div class="md-step-bar-right" id="lineaEstDec"></div>
        </div>
        <div class="md-step" id="stepDecision">
          <i class="fas fa-city fa-2x icono"></i>
          <div class="md-step-optional">
            <!-- <span class="stepFecha">30/02/2020</span> -->
          </div>
          <div class="md-step-bar-left" id="lineaDecEst"></div>
          <!-- <div class="md-step-bar-right"></div>-->
        </div>
      </div>
    </div>
  </div>
  <div class="col-md-7 card-credito">
    <nav class="navbar navbar-expand-sm navbar-sucess my-navbar barra">
      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarNav">
        <strong>
          <ul class="navbar-nav">
<!--            <mat-icon>location_off</mat-icon>-->
            <li class="nav-item active">
             <a class="nav-link ajusteNavar">
               <i class="fas fa-pencil-alt fa-2x icono"></i>
               <p class="texto">Completar</p></a>
            </li>
            <li class="nav-item">
              <a class="nav-link ajusteNavar" (click)="firma()">
                <i  class="fas fa-feather-alt fa-2x icono" ></i>
                <p class="texto">Firmar</p></a>
            </li>
            <li class="nav-item">
              <a class="nav-link ajusteNavar">
                <i class="fas fa-infinity fa-2x icono"></i>
                <p class="texto">Otras</p></a>
            </li>
          </ul>
        </strong>
      </div>
    </nav>
    <div class="p-4">
      <div class="card-body pt-3">
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
</div>
