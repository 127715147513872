import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-detalles-usuario',
  templateUrl: './detalles-usuario.component.html',
  styleUrls: ['./detalles-usuario.component.css']
})
export class DetallesUsuarioComponent implements OnInit {
  displayedColumns: string[] = ['nombres', 'estado'];
  pageSize = 10;
  pageSizeOptions: number[] = [5, 10, 25];
  public dataSource =[
    {CNombres: 'Pagaré Inmaterializado', CEstado: 'Para completar y firmar'},
    {CNombres: 'Carta 1', CEstado: 'Para completar y firmar'}];

  constructor() { }

  ngOnInit(): void {
  }

}
