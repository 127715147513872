import { Component, OnInit } from '@angular/core';
import { RespuestaTipoPerfil, TipoPerfil } from '../../../Model/Response/RespuestaTipoPerfil';
import { CargaDatosImpService } from '../../..//services/ImplementacionServicios/carga-datos-imp';
import { NgxSpinnerService } from 'ngx-spinner';
import { TipoIdentificacion, RespuestaTipoIdent } from '../../..//Model/Response/RespuestaTipoIdent';
import { MessageService } from 'primeng/api';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { LoginServiceImp } from '../../..//Services/ImplementacionServicios/login-service-imp';
import { RespuestaGenerica } from '../../../Model/Response/RespuestaGenerica';

@Component({
  selector: 'app-olvido-clave-cliente',
  templateUrl: './olvido-clave-cliente.component.html',
  styleUrls: ['./olvido-clave-cliente.component.css']
})
export class OlvidoClaveClienteComponent implements OnInit {

  public listPerfilCiente: TipoPerfil[];
  public listTipoIdent: TipoIdentificacion[];

  constructor(
    private loginServiceImp: LoginServiceImp,
    private cargaDatosServiceImp: CargaDatosImpService,
    private SpinnerService: NgxSpinnerService,
    private messageService: MessageService,
    private route: Router
  ) { }

  ngOnInit(): void {
    this.SpinnerService.hide();
    this.obtenerPerfilesClientes();
    this.obtenerTiposIdent();
  }


  obtenerPerfilesClientes() {
    this.SpinnerService.show("sp6");
    this.cargaDatosServiceImp.tipoPerfilCliente().then(
      (response: RespuestaTipoPerfil) => {
        if (response != null && response.flag) {
          this.SpinnerService.hide("sp6");
          this.listPerfilCiente = response.listaTipoPerfil;
        } else {
          this.SpinnerService.hide("sp6");
        }
      }
    )
  }

  obtenerTiposIdent() {
    this.SpinnerService.show("sp6");
    this.cargaDatosServiceImp.tiposIden().then(
      (response: RespuestaTipoIdent) => {
        if (response != null && response.flag) {
          this.SpinnerService.hide("sp6");
          this.listTipoIdent = response.listaTipoIdent;
        } else {
          this.SpinnerService.hide("sp6");
        }
      }
    )
  }

  async restaurarClave(form: NgForm) {
    if (form.valid) {
      let iTipoPerfil = form.form.value.tipoPerfil;
      let iTipoIdent = form.form.value.tipoIdent;
      let ident = form.form.value.usuario;
      this.SpinnerService.show("sp6");
      if (iTipoPerfil != null && iTipoPerfil != "" && iTipoIdent != null && iTipoIdent != "") {
        if (ident != null && ident != "") {
          this.generarClaveNueva(iTipoPerfil, iTipoIdent, ident);
        } else {
          this.messageService.add({ key: 'msgToast', severity: 'warn', summary: '¡Atención!', detail: 'Por favor verifique el fomrulario, debe ingresar información de usuario.' });
          this.SpinnerService.hide("sp6");
        }
      } else {
        this.messageService.add({ key: 'msgToast', severity: 'warn', summary: '¡Atención!', detail: 'Por favor verifique el fomrulario, debe ingresar información de tipo de perfil y tipo de identificación.' });
        this.SpinnerService.hide("sp6");
      }
    } else {
      this.messageService.add({ key: 'msgToast', severity: 'warn', summary: '¡Atención!', detail: 'No se ha validado el formulario correctamente.' });
      this.SpinnerService.hide("sp6");
    }
  }

  generarClaveNueva(iTipoPerfil: number, iTipoIdent: any, ident: any) {
    this.SpinnerService.show("sp6");
    try {
      let xsData = btoa(iTipoIdent + ";" + ident + ";" + iTipoPerfil);
      this.loginServiceImp.generarNuevaClave(xsData).then(
        (data: RespuestaGenerica) => {
          if (data == null) {
            this.SpinnerService.hide("sp6");
            this.messageService.add({ key: 'msgToast', severity: 'warn', summary: '¡Error!', detail: "Se ha presentando un inconveniente inesperado." });
          } else {
            if (!data.bRta) {
              this.messageService.add({ key: 'msgToast', severity: 'warn', summary: '¡Atención!', detail: escape(data.sMsj) });
              this.SpinnerService.hide("sp6");
            } else {
              this.messageService.add({ key: 'msgToast', severity: 'success', summary: '¡Éxito!', detail: data.sMsj });
              this.SpinnerService.hide("sp6");
              setTimeout(() => {
                this.volverInicioSesion();
              }, 1500);
            }
          }
        }
      )
    } catch (error) {
      this.messageService.add({ key: 'msgToast', severity: 'error', summary: '¡Error!', detail: 'Ha ocurrido un inconveniente inesperado.' });
      this.SpinnerService.hide("sp6");
    }
  }

  volverInicioSesion() {
    this.route.navigate(['PincWeb/sesion/login']);
  }

}
