import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { RespuestaTipoPerfil } from '../../Model/Response/RespuestaTipoPerfil';
import { RespuestaTipoIdent } from '../../Model/Response/RespuestaTipoIdent';
import { SolicitudPagare } from '../../Model/DTO/solicitud-pagare';
import { ResponseDetalleFirmaPagare } from '../../Model/Response/response-detalle-firma-pagare';
import { ResponseObtenerDocumento } from '../../Model/Response/response-obtener-documento';
import { RespuestaCiudad, Ciudad } from '../../Model/Response/response-ciudades';
@Injectable({
  providedIn: 'root'
})
export class CargaDatosService {

  public url = environment.URL_SERVICE + "CargaDatosPinc";
  public headers = new HttpHeaders(
    {
      "Content-Type": "application/json",
      "Authorization": "Bearer" + sessionStorage.getItem("token")
    }
  );
  constructor(private http: HttpClient) { }

  tipoPerfil() {
    let path = this.url + "/tipoPerfilEmpleados";
    return this.http.post<RespuestaTipoPerfil>(path, { headers: this.headers });
  }

  findAlltipoPerfil() {
    let path = this.url + "/tipoPerfil";
    return this.http.post<RespuestaTipoPerfil>(path, { headers: this.headers });
  }

  tipoPerfilCliente() {
    let path = this.url + "/tipoPerfilCliente";
    return this.http.post<RespuestaTipoPerfil>(path, { headers: this.headers });
  }


  tipoPerfilById(xiCod: string) {
    let path = this.url + "/tipoPerfilById";
    path = "?xiCod=" + xiCod;
    return this.http.post<RespuestaTipoPerfil>(path, { headers: this.headers });
  }

  tiposIden() {
    let path = this.url + "/tiposIden";
    return this.http.post<RespuestaTipoIdent>(path, { headers: this.headers });
  }

  tiposIdenById(xCod: string) {
    let path = this.url + "/tiposIdenById?xiCod=" + xCod;
    return this.http.post(path, { headers: this.headers });
  }

  obtenerDetalleFirmaDocumento(xiIdProceso: string) {
    let path = this.url + "/obtenerDetalleFirmaDocumento";
    path += "?xiIdProceso=" + xiIdProceso;
    return this.http.post<ResponseDetalleFirmaPagare>(path, { headers: this.headers });
  }

  obtenerDocumentoPagare(solicitudPagare: SolicitudPagare) {
    let path = this.url + "/obtenerDocumentoPagare";
    return this.http.post<ResponseObtenerDocumento>(path, solicitudPagare, { headers: this.headers });
  }

  obtenerCiudades(){
    let path = this.url + '/ciudades';
    return this.http.post<RespuestaCiudad>(path, { headers: this.headers });
  }

}
