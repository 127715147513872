<nav class="navbar navbar-expand-lg navbar-light bg-white " style="padding-left: 30px;">
  <a class="navbar-brand" href="#">
    <img style="width: 100%;" src="./assets/Imagenes/logo.png" alt="" class="img"/>
  </a> 
  <div class="collapse navbar-collapse" id="navbarSupportedContent">
    <ul class="navbar-nav ml-auto mr-auto itemMenu">
      <li class="nav-item">
        <i class="fa fa-question-circle-o icono"></i>
      </li>
    </ul>
  </div>
</nav>
