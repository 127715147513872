import { Injectable } from '@angular/core';
import { GestorSolPagareService } from '../ConsumoServicios/gestor-sol-pagare.service';
import { ResponseSolicitudPagare } from 'src/app/Model/Response/response-solicitud-pagare';
import { RespuestaGenerica } from '../../Model/Response/RespuestaGenerica';
import { ResponseDetalleFirmaPagare } from 'src/app/Model/Response/response-detalle-firma-pagare';
import { SolicitudPagare } from 'src/app/Model/solicitud-pagare';
import { ResponseObtenerDocumento, ResponseObtenerMultiplesDocs } from 'src/app/Model/Response/response-obtener-documento';
import { RequestCrearPagare } from 'src/app/Model/request/request-crear-pagare';

@Injectable({
  providedIn: 'root'
})
export class GestorSolPagareImpService {

  constructor(private gestorSolPagareService: GestorSolPagareService) { }

  cargarSolicitudesPagare() {
    let lista = [];
    return new Promise(resolve => {
      this.gestorSolPagareService.obtenerSolicitudesPagare().subscribe(
        (responseSolPagare: ResponseSolicitudPagare) => {
          lista = responseSolPagare.listaSolicitudPagare;
          resolve(lista);
        }
      );
    });
  }

  cargarEstadoPagare(xiIdProceso: string) {
    return new Promise(resolve => {
      this.gestorSolPagareService.cargarEstadoPagare(xiIdProceso).subscribe(
        (responseSolPagare: RespuestaGenerica) => {
          resolve(responseSolPagare);
        }
      );
    });
  }

  cargarDetalleFirmaDocumento(xiIdProceso: string) {
    return new Promise(resolve => {
      this.gestorSolPagareService.obtenerDetalleFirmaDocumento(xiIdProceso).subscribe(
        (responseDetalleFirma: ResponseDetalleFirmaPagare) => {
          resolve(responseDetalleFirma);
        }
      );
    });
  }

  reenviarOTPSolPagare(solicitudPagare: SolicitudPagare) {
    return new Promise(resolve => {
      this.gestorSolPagareService.reenviarOTPSolPagare(solicitudPagare).subscribe(
        (response: RespuestaGenerica) => {
          if (response != null && response.bRta != null && response.bRta) {
            resolve(response);
          } else {
            resolve('ERROR');
          }
        }
      );
    });
  }

  savaSolicitudPagare(tipoIdent: any, Ident: any, xiTipoPerfil: number, reqCodeudor: string) {
    return new Promise(resolve => {
      this.gestorSolPagareService.savaSolicitudPagare(tipoIdent, Ident, xiTipoPerfil, reqCodeudor).subscribe(
        (responseDetalleFirma: RespuestaGenerica) => {
          resolve(responseDetalleFirma);
        }
      );
    });
  }

  saveSolicitudPagare(requestCrearPagare: RequestCrearPagare) {
    return new Promise(resolve => {
      this.gestorSolPagareService.crearSolicitudPagare(requestCrearPagare).subscribe(
        (responseDetalleFirma: RespuestaGenerica) => {
          resolve(responseDetalleFirma);
        }
      );
    });
  }

  obtenerMultiplesDocs(solicitudPagare: SolicitudPagare) {
    return new Promise(resolve => {
      this.gestorSolPagareService.obtenerMultiplesDocs(solicitudPagare).subscribe(
        (response: ResponseObtenerMultiplesDocs) => {
          if (response != null && response.respuesta != null && response.respuesta.codigo == 1) {
            resolve(response);
          } else {
            resolve("ERROR");
          }
        }
      );
    });
  }


  obtenerDocumentoPagare(solicitudPagare: SolicitudPagare) {
    return new Promise(resolve => {
      this.gestorSolPagareService.obtenerDocumentoPagare(solicitudPagare).subscribe(
        (response: ResponseObtenerDocumento) => {
          if (response != null && response.respuestaBase != null && response.respuestaBase.codigo == 1) {
            resolve(response);
          } else {
            resolve('ERROR');
          }
        }
      );
    });
  }
}
