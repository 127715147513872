import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';


@Component({
  selector: 'app-completar-firmar',
  templateUrl: './completar-firmar.component.html',
  styleUrls: ['./completar-firmar.component.css']
})
export class CompletarFirmarComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit(): void {
  }

  pagare(){
    this.router.navigate(['sesion/completar-firmar/documento']);
  }

  carta(){
    this.router.navigate(['sesion/completar-firmar/documento']);
  }

  firma(){
    this.router.navigate(['sesion/completar-firmar/firma']);
  }

}
