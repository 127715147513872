import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { RespuestaGenerica } from '../../Model/Response/RespuestaGenerica';

@Injectable({
    providedIn: 'root'
})
export class SeguridadClienteService {

    public url = environment.URL_SERVICE + "/SeguridadPinc";
    public headers = new HttpHeaders(
        {
            "Content-Type": "application/json; charset=UTF-8",
            "Authorization": "Bearer" + sessionStorage.getItem("token")
        }
    );

    constructor(private http: HttpClient) { }

    cambioClavePrimerIngreso(xCodUsuario: string, xClaveAntigua: string, xClaveNueva: string, xClaveNuevaConf: string) {
        let path = this.url + "/CambiarPrimeraClave";
        path += "?xCodUsuario=" + xCodUsuario;
        path += "&xClaveAntigua=" + xClaveAntigua;
        path += "&xClaveNueva=" + xClaveNueva;
        path += "&xClaveNuevaConf=" + xClaveNuevaConf
        path += "&xiTipoCanal=1";
        return this.http.post<RespuestaGenerica>(path, { headers: this.headers });
    }

    parametrosClave() {
        let path = this.url + "/ParametrosCambioClave";
        return this.http.post(path, { headers: this.headers });
    }

    validarIdentidadAsociado(xiCodUser: any, sFechaExp: string) {
      let path = this.url + "/validarIdentidad";
      path += "?xiCodUser=" + xiCodUser;
      path += "&sFechaExp=" + sFechaExp;
      return this.http.post<RespuestaGenerica>(path, { headers: this.headers });
  }

    validarIdentidadCodeudor(xiCodUser: any,xTipoDocumento:any,xIdentificacion:any, sFechaExp: string) {
      let path = this.url + "/validarCodeudor";
      path += "?xiCodUser=" + xiCodUser;
      path += "&xTipoDocumento=" + xTipoDocumento;
      path += "&xIdentificacion=" + xIdentificacion;
      path += "&sFechaExp=" + sFechaExp;
      return this.http.post<RespuestaGenerica>(path, { headers: this.headers });
  }

    generarNuevaClave(xData: string) {
        let path = this.url + "/Gestor/generarNuevaClave";
        path += "?xsData=" + xData;
        return this.http.post<RespuestaGenerica>(path, { headers: this.headers });
    }

    desbloquearUsuario(xData: string) {
        let path = this.url + "/Gestor/desbloquearUsuario";
        path += "?xsData=" + xData;
        return this.http.post<RespuestaGenerica>(path, { headers: this.headers });
    }
}
