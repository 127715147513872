import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { MessageService } from 'primeng/api';
import { CargaDatosImpService } from 'src/app/services/ImplementacionServicios/carga-datos-imp';
import { RespuestaTipoIdent, TipoIdentificacion } from 'src/app/Model/Response/RespuestaTipoIdent';
import { RespuestaTipoPerfil, TipoPerfil } from 'src/app/Model/Response/RespuestaTipoPerfil';
import { CargarTodosUsuariosImpService } from 'src/app/services/ImplementacionServicios/cargar-todos-usuarios-imp';
import { NgForm } from '@angular/forms';
import { RespuestaPersona, persona } from 'src/app/Model/Response/RespuestaPersona';
import { RespuestaGenerica } from 'src/app/Model/Response/RespuestaGenerica';
import { SeguridadClienteImp } from 'src/app/Services/ImplementacionServicios/seguridad-cliente-imp';

@Component({
  selector: 'app-reiniciar-clave',
  templateUrl: './reiniciar-clave.component.html',
  styleUrls: ['./reiniciar-clave.component.css']
})
export class ReiniciarClaveComponent implements OnInit {


  public Visible: boolean;
  public listPerfilEmpleados: TipoPerfil[];
  public listTipoIdent: TipoIdentificacion[];
  public findPersona: persona;
  private iTipoPerfilSelected: string;
  constructor(
    private route: Router,
    private SpinnerService: NgxSpinnerService,
    private CargaDatosImpService: CargaDatosImpService,
    private cargarTodosUsuariosImpService: CargarTodosUsuariosImpService,
    private seguridadServiceImp: SeguridadClienteImp,
    private messageService: MessageService
  ) { }

  ngOnInit(): void {
    this.Visible = false;
    this.listPerfilEmpleados = null;
    this.listTipoIdent = null;
    this.obtenerPerfilesEmpleados();
    this.obtenerTiposIdent();
  }


  obtenerPerfilesEmpleados() {
    this.SpinnerService.show("sp6");
    this.CargaDatosImpService.findAlltipoPerfil().then(
      (response: RespuestaTipoPerfil) => {
        if (response != null && response.flag) {
          this.SpinnerService.hide("sp6");
          this.listPerfilEmpleados = response.listaTipoPerfil;
        } else {
          this.SpinnerService.hide("sp6");
        }
      }
    )
  }

  obtenerTiposIdent() {
    this.SpinnerService.show("sp6");
    this.CargaDatosImpService.tiposIden().then(
      (response: RespuestaTipoIdent) => {
        if (response != null && response.flag) {
          this.SpinnerService.hide("sp6");
          this.listTipoIdent = response.listaTipoIdent;
        } else {
          this.SpinnerService.hide("sp6");
        }
      }
    )
  }

  findCliente(form: NgForm) {
    this.SpinnerService.show("sp6");
    this.messageService.clear();
    this.Visible = false;
    let iTipoPerfil = form.form.value.tipoPerfil;
    let iTipoIdent = form.form.value.tipoIdent;
    let Cedula = form.form.value.Cedula;
    if (iTipoPerfil !== null && iTipoPerfil !== undefined && iTipoIdent !== null && iTipoIdent !== undefined && Cedula !== null && Cedula !== undefined) {
      this.cargarTodosUsuariosImpService.BuscarPersonaByIdentAndPerfil(iTipoPerfil, btoa(iTipoIdent), btoa(Cedula)).then(
        (data: RespuestaPersona) => {
          this.SpinnerService.hide('sp6');
          if (data != null && data.bRta) {
            this.iTipoPerfilSelected = iTipoPerfil;
            this.messageService.add({ key: 'msgToast', severity: 'success', summary: '¡Excelente!', detail: data.sMsj });
            this.findPersona = data.persona;
            this.Visible = true;
          } else {
            this.SpinnerService.hide('sp6');
            this.messageService.add({ key: 'msgToast', severity: 'warn', summary: '¡ALERTA!', detail: data.sMsj });
          }
        });
    } else {
      this.SpinnerService.hide('sp6');
      this.messageService.add({ key: 'msgToast', severity: 'warn', summary: '¡ALERTA!', detail: 'Por favor seleccione el tipo de identificación e ingrese el número de identificación a buscar como asocido.' });
    }
  }

  desbloquear() {
    this.SpinnerService.show("sp6");
    try {
      let xsData = btoa(this.findPersona.ITipoIdent.ICodigo + ";" + this.findPersona.CIdentificacion + ";" + this.iTipoPerfilSelected);
      this.seguridadServiceImp.desbloquearUsuario(xsData).then(
        (data: RespuestaGenerica) => {
          if (data == null) {
            this.SpinnerService.hide("sp6");
            this.messageService.add({ key: 'msgToast', severity: 'warn', summary: '¡Error!', detail: "Se ha presentando un inconveniente inesperado." });
          } else {
            if (!data.bRta) {
              this.messageService.add({ key: 'msgToast', severity: 'warn', summary: '¡Atención!', detail: data.sMsj });
              this.SpinnerService.hide("sp6");
            } else {
              this.messageService.add({ key: 'msgToast', severity: 'info', summary: '¡Éxito!', detail: data.sMsj });
              this.SpinnerService.hide("sp6");
            }
          }
        }
      )
    } catch (error) {
      this.messageService.add({ key: 'msgToast', severity: 'error', summary: '¡Error!', detail: 'Ha ocurrido un inconveniente inesperado.' });
      this.SpinnerService.hide("sp6");
    }
  }

  Restablecer() {
    this.SpinnerService.show("sp6");
    try {
      let xsData = btoa(this.findPersona.ITipoIdent.ICodigo + ";" + this.findPersona.CIdentificacion + ";" + this.iTipoPerfilSelected);
      this.seguridadServiceImp.generarNuevaClave(xsData).then(
        (data: RespuestaGenerica) => {
          if (data == null) {
            this.SpinnerService.hide("sp6");
            this.messageService.add({ key: 'msgToast', severity: 'warn', summary: '¡Error!', detail: "Se ha presentando un inconveniente inesperado." });
          } else {
            if (!data.bRta) {
              this.messageService.add({ key: 'msgToast', severity: 'warn', summary: '¡Atención!', detail: data.sMsj });
              this.SpinnerService.hide("sp6");
            } else {
              this.messageService.add({ key: 'msgToast', severity: 'info', summary: '¡Éxito!', detail: data.sMsj });
              this.SpinnerService.hide("sp6");
            }
          }
        }
      )
    } catch (error) {
      this.messageService.add({ key: 'msgToast', severity: 'error', summary: '¡Error!', detail: 'Ha ocurrido un inconveniente inesperado.' });
      this.SpinnerService.hide("sp6");
    }
  }

}
