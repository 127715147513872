import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-home-admin',
  templateUrl: './home-admin.component.html',
  styleUrls: ['./home-admin.component.css']
})
export class HomeAdminComponent implements OnInit {

  constructor(
    private router: Router
    ) { }

  ngOnInit(): void {
  }

  irInicio() {
    this.router.navigate(['PincWeb/gestion/gestion-usuarios']);
  }

  irPagares() {
    this.router.navigate(['PincWeb/gestion/gestion-pagare']);
  }

  irSeguridad() {
    this.router.navigate(['PincWeb/gestion/reiniciarClaveCliente']);
  }



}
