import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { DialogComponent } from '../dialog/dialog.component';



@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css'],
})
export class FooterComponent implements OnInit {

  @Input() public texto: string;
  @Input() public anterior: string;
  @Input() public siguiente: string;
  @Input() public btnSiguiente: string;
  @Input() public btnAnterior: string;
  @Input() public avanzar: boolean;
  @Input() public idMenu: string;
  @Input() public modal: boolean;
  @Input() public documentosRequeridos: boolean;

  public display: boolean = false;
  public contenidoModal = null;
  public CreditoGuardado;
  public Doc;


  constructor(private route: Router,
    private dialog: MatDialog) {

  }

  ngOnInit(): void {
    this.CreditoGuardado = sessionStorage.getItem("Credito");
    this.Doc = sessionStorage.getItem("Doc");
  }

  abrirDialog(xTitulo: string, xMensaje: string, tipoDialog: string) {

    let dataC = {
      titulo: xTitulo,
      texto: xMensaje,
      tipoDialog: tipoDialog,
    };

    let dialogRef = this.dialog.open(DialogComponent, { data: dataC, panelClass: 'prueba' });
    dialogRef.afterClosed().subscribe(
      (result: any) => {
      }
    )
  }

  async irSiguiente() {

    if (this.avanzar) {
      this.route.navigate(['solicitudCredito/' + this.siguiente]);
    }
    else {
      if (this.idMenu=="document") {
        if (this.documentosRequeridos) {
          this.route.navigate(['solicitudCredito/' + this.siguiente]);
        }
        else{
          this.abrirDialog("Error","Debe subir los documentos que son requeridos","warning");
        }

      }else{
        this.route.navigate(['solicitudCredito/' + this.siguiente]);
      }
    }
  }

  irAnterior() {
    if (this.anterior == "bienvenido") {
      this.route.navigate(['sesion/bienvenido']);
    }
    else {
      this.route.navigate(['solicitudCredito/' + this.anterior]);
    }
  }
  cerrarModal() {
    this.display = false;
  }


}
