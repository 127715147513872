<ngx-spinner name="sp6" type="timer" size="medium">
    <p style="color: white"> Espere un momento... </p>
</ngx-spinner>
<div class="encabezado">
    <h1 class="title">Cambiar contraseña de asociado </h1>
</div>
<div class="row centro">
    <p class="parrafo">
        Para cambiar o desbloquear la contraseña de un usuario , búscalo ingresa la información solicitada, realizada la
        busqueda y seleccione la opción deseada.
        contraseña.
    </p>
</div>

<div class="cuerpo">
    <p-toast position="top-right" key="msgToast"></p-toast>
    <div class="row">
        <div class=" col-lg-7 col-md-6 col-12 datos">
            <div class="cajabusqueda">
                <form class="login100-form validate-form" ngNativeValidate #formFindCliente="ngForm"
                    id="formFindCliente" name="formFindCliente" (ngSubmit)="findCliente(formFindCliente)">
                    <p class="labelInput">Tipo de perfil:</p>
                    <select name="tipoPerfil" name="tipoPerfil" class="form-control" #selectedTipoPerfil="ngModel"
                        (ngModel)="selectedTipoPerfil">
                        <option value="" disabled>Selecciona tu tipo de perfil</option>
                        <option value="{{item.ICodigo}}" *ngFor="let item of listPerfilEmpleados" class="form-control">
                            {{item.CDescripcion}}
                        </option>
                    </select>
                    <p class="labelInput">Tipo de identificación:</p>
                    <select name="tipoIdent" name="tipoIdent" class="form-control" #selectedTipoident="ngModel"
                        (ngModel)="selectedTipoident">
                        <option value="" disabled>Selecciona tu tipo de identificación</option>
                        <option value="{{item.ICodigo}}" *ngFor="let item of listTipoIdent" class="form-control">
                            {{item.CDescripcion}}
                        </option>
                    </select>
                    <label class="labelInput" for="">Identificación</label>
                    <input type="text" class="form-control" name="Cedula" #xCedula="ngModel" (ngModel)="xCedula">
                    <button type="submit" pButton pRipple class="p-button-raised" icon="pi pi-search" label="BUSCAR"
                        name="btnBuscar"></button>
                </form>
            </div>
        </div>

        <div class="col-lg-4 col-md-6 col-12 form">
            <div class="Centro" *ngIf=" Visible==true">
                <p-card class="tarjeta" header="Información persona"
                    [style]="{'background': 'lightgray'}">
                    <p class="parrafoInformacion"><b>Nombres :
                        </b>{{ findPersona.CNombres +' ' + findPersona.CApellidos }}</p>
                    <p class="parrafoInformacion"><b>Tipo de identificación :
                        </b>{{ findPersona.ITipoIdent.CDescripcion}}</p>
                    <p class="parrafoInformacion"><b>Identificación :
                        </b>
                        {{ findPersona.CIdentificacion }}</p>
                    <p class="parrafoInformacion"><b>Email :
                        </b>{{ findPersona.CEmail }}</p>
                    <p class="parrafoInformacion"><b>N° de celular :
                        </b>{{ findPersona.CNumCelular }}</p>
                    <ng-template pTemplate="footer" [style]="{'margin-left': '10px'}">
                        <p-button label="RESTAURAR" icon="pi pi-refresh" (click)="Restablecer()" [style]="{'display': 'inline-grid'}"></p-button>
                        <p-button label="DESBLOQUEAR" icon="pi pi-unlock" (click)="desbloquear()" styleClass="p-button-secondary" [style]="{'margin-left': '.5em', 'display': 'inline-grid'}"></p-button>
                    </ng-template>
                </p-card>
            </div>
        </div>
    </div>
</div>
