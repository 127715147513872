import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { TokenImpService } from "../Services/ImplementacionServicios/token-imp";

@Injectable({
  providedIn: 'root'
})
export class LoginGuardGuard implements CanActivate {
  
  constructor(
    private router: Router,
    private jwtTokenImpService: TokenImpService
  ) {
  }

  canActivate() {
    let isLogin = this.jwtTokenImpService.getbRta();
    if (isLogin) {
      return true;
    } else {
      this.router.navigate(['sesion/login']);
      return false;
    }
  }


}
