import { Component, OnInit } from "@angular/core";
import { TokenImpService } from '../../../Services/ImplementacionServicios/token-imp';
import { LoginServiceImp } from '../../../Services/ImplementacionServicios/login-service-imp';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: "app-menu",
  templateUrl: "./menu.component.html",
  styleUrls: ["./menu.component.css"]
})
export class MenuComponent implements OnInit {

  public nombre: string;

  constructor(
  ) { }

  ngOnInit(): void {
    
  }

}
