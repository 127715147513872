import { Injectable } from '@angular/core';
import { CargarTodosUsuariosService } from '../ConsumoServicios/cargar-todos-usuarios.service';
import { HttpErrorResponse } from '@angular/common/http';
import { PersonaRe } from '../../Model/DTO/PersonaRegistry';
import { RespuestaPersona } from 'src/app/Model/Response/RespuestaPersona';

@Injectable({
  providedIn: 'root'
})
export class CargarTodosUsuariosImpService {

  constructor(private cargarTodosUsuarios: CargarTodosUsuariosService) { }

  cargarUsuarios() {
    let lista = [];
    return new Promise(resolve => {
      this.cargarTodosUsuarios.cargaTodosUsuarios().subscribe(
        (response: any) => {
          lista = response.listaUsuarios;
          resolve(lista);
        }
      );
    });
  }

  BuscarUsuario(tipoIdent: any, Ident: any) {
    return new Promise(resolve => {
      this.cargarTodosUsuarios.FindPersonaByIdent(tipoIdent, Ident).subscribe(
        (response: any) => {
          resolve(response);
        }
      );
    });
  }

  BuscarPersonaByIdentAndPerfil(xiTipoPerfil: number, xTipoIdent, xIdent) {
    return new Promise(resolve => {
      this.cargarTodosUsuarios.FindPersonaByIdentAndPerfil(xiTipoPerfil, xTipoIdent, xIdent).subscribe(
        (response: RespuestaPersona) => {
          resolve(response);
        }
      );
    });
  }

  registrarUsuario(persona: string, xiTipoPerfil: number, xCiudad: number) {
    return new Promise(resolve => {
      this.cargarTodosUsuarios.SavePersona(persona, xiTipoPerfil, xCiudad).subscribe(
        (response: any) => {
          resolve(response);
        }
      );
    });
  }

}
