import { Injectable } from '@angular/core';
import { LoginService } from '../ConsumoServicios/login.service';
import { TokenImpService } from './token-imp';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { UtilidadService } from '../../General/utilidad.service';
import { RespuestaGenerica } from '../../Model/Response/RespuestaGenerica';
import { DialogComponent } from '../../PincWeb/pages/dialog/dialog.component';

@Injectable({
  providedIn: 'root'
})
export class LoginServiceImp {

  constructor(
    private utilService: UtilidadService,
    private loginService: LoginService,
    private dialog: MatDialog,
    private route: Router
  ) { }



  loginCliente(xTipoPerfil: number, xTipoIdent: string, xIdent: string, xClave: string) {
    let xNavegador = this.utilService.obtenerNavegadorVersion();
    return new Promise(resolve => {
      this.loginService.loginCliente(xTipoPerfil, xTipoIdent, xIdent, xClave, xNavegador).subscribe(
        (response: any) => {
          resolve(response);
        },
        (error: HttpErrorResponse) => {
          resolve("Fallo");
        }
      );
    })
  }


  loginEmpleado(xTipoPerfil: string, xTipoIdent: string, xIdent: string, xClave: string) {
    let xNavegador = this.utilService.obtenerNavegadorVersion();
    return new Promise(resolve => {
      this.loginService.loginUsuarioEmpleado(xTipoPerfil, xTipoIdent, xIdent, xClave, xNavegador).subscribe(
        (response: any) => {
          resolve(response);
        },
        (error: HttpErrorResponse) => {
          resolve("error");
        }
      );
    })
  }

  olvidoClave(xTipoIdent: string, xIdent: string, xClave: string) {
    let xNavegador = this.utilService.obtenerNavegador();
    this.loginService.olvidoClave(xTipoIdent, xIdent, xClave, xNavegador).subscribe(
      (response: any) => {
      }
    );
  }


  cambioClavePrimerIngreso(xCodUsuario: string, xClaveAntigua: string, xClaveNueva: string, xClaveNuevaConf: string) {
    return new Promise(resolve => {
      this.loginService.cambioClavePrimerIngreso(xCodUsuario, xClaveAntigua, xClaveNueva, xClaveNuevaConf).subscribe(
        (response: any) => {
          resolve(response);
        }
      );
    });
  }

  validarPersona(xiTipoIden: string, xcIdentificacion: string) {
    this.loginService.validarPersona(xiTipoIden, xcIdentificacion).subscribe(
      (response: any) => {
      }
    )
  }

  cerrarSession(xiUsuario: string) {
    return new Promise(resolve => {
      this.loginService.cerrarSession(xiUsuario).subscribe(
        (response: any) => {
          resolve(response);
        },
        (error: HttpErrorResponse) => {
          this.abrirDialog("¡Error!", "Ha ocurrido un error de conexión", "warning", "inicio");

        }
      )
    });
  }

  validarOlvidoClave(xData: string) {
    return new Promise(resolve => {
      this.loginService.validarOlvidoClave(xData).subscribe(
        (response: any) => {
          resolve(response);
        },
        (error: HttpErrorResponse) => {
         resolve("Error");
        }
      );
    });
  }


  generarNuevaClave(xData: string) {
    return new Promise(resolve => {
      this.loginService.generarNuevaClave(xData).subscribe(
        (response: RespuestaGenerica) => {
          resolve(response);
        },
        (error: HttpErrorResponse) => {
         resolve(null);
        }
      );
    });
  }

  obtenerIP() {
    return new Promise(resolve => {
      this.utilService.getIp().subscribe(
        (res: any) => {
          resolve(res.ip);
        }
      );
    });
  }

  abrirDialog(xTitulo: string, xMensaje: string, tipoDialog: string, redireccion: string) {
    let dataC = {
      titulo: xTitulo,
      texto: xMensaje,
      tipoDialog: tipoDialog,


    };
    let dialogRef = this.dialog.open(DialogComponent, { data: dataC, panelClass: 'prueba' });
    dialogRef.afterClosed().subscribe(
      (result: any) => {

        if (redireccion == "N/A") {
          this.route.navigate(['/cambiarClave']);
        } else if (redireccion == "inicio") {
          this.route.navigate(['session/login']);
        } else {
          this.route.navigate(['sesion/bienvenido']);
        }
      }
    )
  }
}
