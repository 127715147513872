<div align="center">
  <div class="rectangle-cambiar-contrasenia">
    <div class="row">
      <div class="col-12">
        <div class="row">

          <div class="col-6 box-image">
            <div class="imagen">
              <img class="imagen-perfil" src="assets/Imagenes/oval.png">
            </div>
          </div>

          <div class="col-6 box-datos-personales">
            <div class="datos_personales">
              <p class="txtForm">Usuario</p>
              <input class="form-control inputloginmb-3" type="text" placeholder="Nombre de usuario" name="usuario" required="true" #xUsuario="ngModel" (ngModel)="xUsuario" />
              <p class="txtForm">Código usuario</p>
              <input class="form-control inputloginmb-3" type="password" placeholder="Código" name="clave" #clave="ngModel" (ngModel)="clave" required="true" />
            </div>
          </div>
        </div>

      </div>
    </div>
    <div class="col-12 box-datos-personales">
      <div class="datos_personales">
        <p class="txtForm">Codigo de solicitud</p>
        <input class="form-control inputloginmb-3" type="text" placeholder="Código" name="usuario" required="true" #xUsuario="ngModel" (ngModel)="xUsuario" />
      </div>
      <div class="tabla">
        <div>
          <table mat-table class="tablaAs" [dataSource]="dataSource" matSort>

            <ng-container matColumnDef="nombres">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>  </th>
              <td mat-cell *matCellDef="let element" class="asociado">{{element.CNombres}}</td>
            </ng-container>

            <ng-container matColumnDef="estado">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>  </th>
              <td mat-cell *matCellDef="let element" class="asociado">{{element.CEstado}}</td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns "></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns; "></tr>
          </table>
          <mat-paginator [pageSize]="pageSize " [pageSizeOptions]="pageSizeOptions ">
          </mat-paginator>
        </div>
      </div>
    </div>
  </div>
</div>
