import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoginServiceImp } from '../../../Services/ImplementacionServicios/login-service-imp';
import { NgxSpinnerService } from 'ngx-spinner';
import { TokenImpService } from '../../../Services/ImplementacionServicios/token-imp';
import { CargaDatosImpService } from '../../../services/ImplementacionServicios/carga-datos-imp';
import { RespuestaTipoPerfil, TipoPerfil } from '../../../Model/Response/RespuestaTipoPerfil';
import { TipoIdentificacion, RespuestaTipoIdent } from '../../../Model/Response/RespuestaTipoIdent';
import { MessageService } from 'primeng/api';
import { NgForm } from '@angular/forms';
import { RespuestaGenerica } from '../../../Model/Response/RespuestaGenerica';

@Component({
  selector: 'app-olvido-clave-empleado',
  templateUrl: './olvido-clave-empleado.component.html',
  styleUrls: ['./olvido-clave-empleado.component.css']
})
export class OlvidoClaveEmpleadoComponent implements OnInit {

  public listPerfilEmpleados: TipoPerfil[];
  public listTipoIdent: TipoIdentificacion[];

  constructor(
    private route: Router,
    private loginServiceImp: LoginServiceImp,
    private SpinnerService: NgxSpinnerService,
    private jwt: TokenImpService,
    private cargaDatosServiceImp: CargaDatosImpService,
    private messageService: MessageService
  ) { }

  ngOnInit(): void {
    this.listPerfilEmpleados = null;
    this.listTipoIdent = null;
    this.obtenerPerfilesEmpleados();
    this.obtenerTiposIdent();
    this.jwt.clearStorage();
  }

  obtenerPerfilesEmpleados() {
    this.SpinnerService.show("sp6");
    this.cargaDatosServiceImp.tipoPerfil().then(
      (response: RespuestaTipoPerfil) => {
        if (response != null && response.flag) {
          this.SpinnerService.hide("sp6");
          this.listPerfilEmpleados = response.listaTipoPerfil;
        } else {
          this.SpinnerService.hide("sp6");
        }
      }
    )
  }

  obtenerTiposIdent() {
    this.SpinnerService.show("sp6");
    this.cargaDatosServiceImp.tiposIden().then(
      (response: RespuestaTipoIdent) => {
        if (response != null && response.flag) {
          this.SpinnerService.hide("sp6");
          this.listTipoIdent = response.listaTipoIdent;
        } else {
          this.SpinnerService.hide("sp6");
        }
      }
    )
  }

  async resturarClaveEmpleado(form: NgForm) {
    if (form.valid) {
      let iTipoPerfil = form.form.value.tipoPerfil;
      let iTipoIdent = form.form.value.tipoIdent;
      let ident = form.form.value.usuario;
      this.SpinnerService.show("sp6");
      if (iTipoPerfil != null && iTipoPerfil != "" && iTipoIdent != null && iTipoIdent != "") {
        if (ident != null && ident != "" ) {
          this.generarClaveNuevaEmpleado(iTipoPerfil, iTipoIdent, ident);
        } else {
          this.messageService.add({ key: 'msgToast', severity: 'warn', summary: '¡Atención!', detail: 'Por favor verifique el fomrulario, debe ingresar información de usuario.' });
          this.SpinnerService.hide("sp6");
        }
      } else {
        this.messageService.add({ key: 'msgToast', severity: 'warn', summary: '¡Atención!', detail: 'Por favor verifique el fomrulario, debe ingresar información de tipo de perfil y tipo de identificación.' });
        this.SpinnerService.hide("sp6");
      }
    } else {
      this.messageService.add({ key: 'msgToast', severity: 'warn', summary: '¡Atención!', detail: 'No se ha validado el formulario correctamente.' });
      this.SpinnerService.hide("sp6");
    }
  }


  generarClaveNuevaEmpleado(iTipoPerfil: number, iTipoIdent: any, ident: any) {
    this.SpinnerService.show("sp6");
    try {
      let xsData = btoa(iTipoIdent + ";" + ident + ";" + iTipoPerfil);
      this.loginServiceImp.generarNuevaClave(xsData).then(
        (data: RespuestaGenerica) => {
          if (data == null) {
            this.SpinnerService.hide("sp6");
            this.messageService.add({ key: 'msgToast', severity: 'warn', summary: '¡Error!', detail: "Se ha presentando un inconveniente inesperado." });
          } else {
            if (!data.bRta) {
              this.messageService.add({ key: 'msgToast', severity: 'warn', summary: '¡Atención!', detail: data.sMsj });
              this.SpinnerService.hide("sp6");
            } else {
              this.messageService.add({ key: 'msgToast', severity: 'success', summary: '¡Éxito!', detail: data.sMsj });
              this.SpinnerService.hide("sp6");
              this.volverInicioSesion();
            }
          }
        }
      )
    } catch (error) {
      this.messageService.add({ key: 'msgToast', severity: 'error', summary: '¡Error!', detail: 'Ha ocurrido un inconveniente inesperado.' });
      this.SpinnerService.hide("sp6");
    }
  }

  volverInicioSesion() {
    this.route.navigate(['PincWeb/sesion/loginAdmin']);
  }

}
